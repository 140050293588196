(function($) {
  var LOADING_TYPE = {
    text: 'text',
    spinner: 'spinner'
  };
  $.fn.loadingEffect = function(options) {
    var that = this,
        defaults = {
          text: 'One moment please',
          background: 'none',
          fontSize: '18',
          textTransform: 'none',
          spaceBetween: '2',
          alignContent: 'left',
          type: LOADING_TYPE.text
        };
    that._CSS_CLASS = 'loading-text-effect';
    that._SPINNER_CLASS = 'loading-spinner';
    that.ELEM_CLASS = 'loading-effect-active';
    this.opts = {};
    this._destroy = function() {
      that.removeClass(that.ELEM_CLASS);
      that.find('.' + that._CSS_CLASS).remove();
      that.find('.' + that._SPINNER_CLASS).remove();
    };
    this._createHTML = function() {
      var html = '<div style="background:' + that.opts.background + '; justify-content:' + that.opts.alignContent + '" class="' + that._CSS_CLASS + '">';
      var chars = this.opts.text.split('');
      that.css('position', 'relative');
      chars.forEach(function(c, i) {
        html += '<span style="padding:0 ' + parseInt(that.opts.spaceBetween) / 2 + 'px;text-transform: ' + that.opts.textTransform + ';font-size:' + that.opts.fontSize + 'px; animation: blur-text 1.5s ' + i / 5 + 's infinite linear alternate;">' + c + '</span>';
      });
      html += '</div>';
      that.addClass(that.ELEM_CLASS);
      that.append(html);
    };
    this._createSpinner = function() {
      var html = '<div class="' + that._SPINNER_CLASS + ' fade-in"></div>';
      that.addClass(that.ELEM_CLASS);
      that.append(html);
    };

    if (typeof options === 'string') {
      switch (options) {
        case 'destroy':
          this._destroy();
          return this;
        default:
          return this;
      }
    }

    this.opts = options ? $.extend({}, defaults, options) : defaults;
    this.opts.type === LOADING_TYPE.text ? this._createHTML() : this._createSpinner();

    return this;
  };
}(jQuery));
